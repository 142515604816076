import React, { Component } from 'react'

export default class Regolamento extends Component {
    componentDidMount = () => {
        document.title = 'Dott. Morganti | Regolamento'
    }

    render() {
        return <div className="paragraph">
            <h1>Regolamento di studio</h1>
            <h2>Coronavirus COVID-19</h2>
            <p>
                <b>
                    In caso di sospetto di malattie da raffreddamento, influenza, iperpiressia, bronchiti ed infezioni
                    delle vie respiratorie, prima di raggiungere l'ambulatorio è obbligatorio <i><u>sempre</u> </i>
                    contattare telefonicamente il medico.
                </b>
            </p>
            <h2>Regolamente generale</h2>
            <ol>
                {/* <li>
                    <p>
                        Dal Lunedì al Venerdì si riceve solo su appuntamento. Le Urgenze verranno visitate nella
                        mezzora antecedente e seguente agli orari di appuntamento. Il Sabato gli assistiti devono
                        prendere il numero al banco e accedere alla sala d’aspetto nell’orario indicato scritto sul foglio d’ingresso.
                </p>
                </li> */}
                <li>
                    <p>
                        Gli esami strumentali, di laboratorio e visite specialistiche non richiesti dal curante
                        devono essere accompagante da richiesta specifica di specialisti con diagnosi e motivazioni
                </p>
                </li>
                <li>
                    <p>
                        La medicina di gruppo prevede che, inassenza del proprio medico, nei casi di necessità
                        effettiva ed urgenza, ci si possa rivolgere agli altri medici. Per tutte le altre situazioni
                        ciascun assistito deve rivolgersi al proprio medico
                </p>
                </li>
                <li>
                    <p>
                        L'uso della strumentazione diagnostica e di laboratorio presente nello studio (spirometro,
                        ecografo, elettrocardiograafo, stick urine, stick glicemia, tamponi faringei) è ad esclusiva
                        discrezione del medico in quanto tali presidi non sono contemplati nel Sistema Sanitario
                        Nazionale
                </p>
                </li>
                <li>
                    <p>Gli informatori scientifici accedono singolarmente previo appuntamento</p>
                </li>
                <li>
                    <p>
                        Le ricette ordinarie e gli esami specialistici nonché le visite specialistiche previste dal
                        protocollo dei pazienti cronici possono essere richieste all'assistente di studio (tel: 0342
                        670686)
                </p>
                </li>
            </ol>
        </div>
    }
}