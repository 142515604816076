import React, { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { IoIosArrowBack, IoIosArrowForward, IoIosCreate, IoIosClose, IoIosTrash } from 'react-icons/io'
import moment from 'moment-timezone'

interface Appointment {
    time: string
    firstname: string
    lastname: string
    email: string
    phone: string
}

interface ReservedState {
    password?: string,
    captchaToken?: string,
    error?: string,
    logged: boolean,
    currents?: JSX.Element[],
    days?: {
        header: string,
        date: string,
        appointments: ({
            taken: boolean
        } & Appointment)[]
    }[],
    currentDay: number,
    selectedAppointment?: string,
    modifying: boolean,
    availableAppointments: {
        screen: string,
        value: string
    }[]
}

export default class Reserved extends Component<{}, ReservedState> {
    capthcaCheckbox: React.RefObject<ReCAPTCHA>
    newTime: React.RefObject<HTMLSelectElement>
    constructor(props: any) {
        super(props)
        this.capthcaCheckbox = React.createRef()
        this.newTime = React.createRef()
        this.state = {
            logged: false,
            currentDay: 0,
            modifying: false,
            availableAppointments: []
        }
        fetch('/api/reserved/logged').then(async res => {
            this.setState({ logged: (await res.json()).success })
        })
        this.refreshAvailableAppointments()
    }

    componentDidMount = () => {
        document.title = 'Dott. Morganti | Area riservata'
    }

    reloadSelector = async () => {
        let res = await fetch('/api/reserved/appointments')
        let obj = await res.json()

        if (obj.success) this.setState({ days: obj.data.days, currentDay: 0, selectedAppointment: undefined })
        else { this.setState({ error: obj.error }) }
    }

    renderSelector: () => JSX.Element[] | undefined = () => {
        if (!this.state.days) return
        let lines: JSX.Element[] = []
        let divisions = 4
        for (let i = 0; i < (this.state.days[this.state.currentDay].appointments.length - parseInt(this.state.days[this.state.currentDay].appointments[0].time.split(':')[1]) / (60 / divisions)) / divisions; i++) {
            lines.push(<div key={"line" + i} className="separatorLine" style={{
                top: (i * divisions + divisions - parseInt(this.state.days[this.state.currentDay].appointments[0].time.split(':')[1]) / (60 / divisions)) * 30
            }} />)
        }

        let appointments: JSX.Element[] = []
        for (const appointment of this.state.days[this.state.currentDay].appointments) {
            let appointmentIdentifier = this.state.currentDay + 'A' + appointment.time
            appointments.push(<div
                key={appointmentIdentifier}
                className={[
                    appointment.taken ? undefined : 'unselectable',
                    this.state.selectedAppointment === appointmentIdentifier ? 'selected' : undefined
                ].join(' ')}
                onClick={() => {
                    if (appointment.taken) {
                        this.setState({ selectedAppointment: appointmentIdentifier })
                        this.refreshAvailableAppointments()
                    }
                }}
            >
                <p>{appointment.time}</p>
            </div>)
        }

        return [
            <div key="weekdaySelector" className="weekdaySelector">
                <div
                    className={"arrowContainer" + (this.state.currentDay < 1 ? ' unselectable' : '')}
                    onClick={() => {
                        if (this.state.currentDay > 0) this.setState({ currentDay: this.state.currentDay - 1 })
                    }}
                >
                    <IoIosArrowBack />
                </div>
                <h2 className="weekday">
                    {this.state.days[this.state.currentDay].header}
                </h2>
                <div
                    className={"arrowContainer flex-right" + (this.state.currentDay > 8 ? ' unselectable' : '')}
                    onClick={() => {
                        if (this.state.currentDay < 9) this.setState({ currentDay: this.state.currentDay + 1 })
                    }}
                >
                    <IoIosArrowForward />
                </div>
            </div>,
            <div key="dayContainer" className="dayContainer">
                {lines}
                <div className="appointmentsContainer">
                    {appointments}
                </div>
            </div>
        ]
    }

    getCurrents: () => Promise<void> = async () => {
        let res = await fetch('/api/reserved/current')
        let o: { success: boolean, data?: { current?: Appointment, next?: Appointment } } = await res.json()
        this.setState({
            currents: [
                <div className="currentcard current" key="current">
                    <p style={{ fontSize: 15, opacity: 0.8 }}>Appuntamento corrente</p>
                    {o.data?.current
                        ? <div>
                            <p style={{ fontSize: 18, opacity: 0.9 }}>{formatDate(o.data.current.time)}</p>
                            <h2>{o.data.current.firstname + ' ' + o.data.current.lastname}</h2>
                            <p>
                                {o.data.current.email} <br />
                                {'+39 ' + o.data.current.phone}
                            </p>
                        </div>
                        : <div>
                            <h2>Nessun appuntamento corrente</h2>
                        </div>
                    }
                </div>,
                <div className="currentcard next" key="next">
                    <p style={{ fontSize: 15, opacity: 0.8 }}>Prossimo appuntamento</p>
                    {o.data?.next
                        ? <div>
                            <p style={{ fontSize: 18, opacity: 0.9 }}>{formatDate(o.data.next.time)}</p>
                            <h2>{o.data.next.firstname + ' ' + o.data.next.lastname}</h2>
                            <p>
                                {o.data.next.email} <br />
                                {'+39 ' + o.data.next.phone}
                            </p>
                        </div>
                        : <div>
                            <h2>Non ci sono più appuntamenti</h2>
                        </div>
                    }
                </div>
            ]
        })
    }

    refreshAvailableAppointments = async () => {
        const res = await fetch('/api/appointments')
        const obj = await res.json()
        if (!obj.success) return
        const days: {
            header: string
            date: string
            appointments: {
                time: string
                available: boolean
            }[]
        }[] = obj.data.days

        let apps: {
            screen: string,
            value: string
        }[] = []

        for (const day of days) {
            let d = new Date(day.date)
            for (const app of day.appointments.filter(a => a.available)) {
                apps.push({
                    screen: `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} - ${app.time}`,
                    value: day.date + 'T' + app.time
                })
            }
        }

        this.setState({ availableAppointments: apps })
    }

    renderInfo = () => {
        if (!this.state.days) return
        let appointment: Appointment | undefined
        for (const app of this.state.days[this.state.currentDay].appointments.filter(app => app)) {
            let appointmentIdentifier = this.state.currentDay + 'A' + app.time
            if (appointmentIdentifier === this.state.selectedAppointment) appointment = app
        }

        if (!appointment || !this.state.selectedAppointment) return <div className="info">
            <p>info appuntamento</p>
            <h2>Seleziona un appuntamento dagli orari e apparirà qua</h2>
        </div>

        let t = this.state.days[parseInt(this.state.selectedAppointment.split('A')[0])].date + 'T' + this.state.selectedAppointment.split('A')[1]

        return <div className="info">
            <p>info appuntamento</p>
            <p>{formatDate(this.state.days[this.state.currentDay].date).substr(0, 11) + appointment.time}</p>
            {this.state.modifying ? <label>Nuovo orario: <select ref={this.newTime}>
                {this.state.availableAppointments.map(a => <option key={a.value} value={a.value}>{a.screen}</option>)}
            </select></label> : undefined}
            <h1>{appointment.firstname + ' ' + appointment.lastname}</h1>
            <p>{'Email: ' + appointment.email}</p>
            <p>{'Telefono: +39 ' + appointment.phone}</p>
            {this.state.modifying ?
                <div className="svg">
                    <IoIosClose viewBox="60 60 392 392" size={40} color="red" onClick={() => this.setState({ modifying: false })} />
                    <div className="save" onClick={async () => {
                        let res = await fetch('/api/reserved/changetime', {
                            method: 'post',
                            body: JSON.stringify({
                                oldtime: t,
                                newtime: this.newTime.current?.value
                            }),
                            headers: {
                                "Content-type": "application/json"
                            }
                        })
                        let o = await res.json()
                        this.setState({ modifying: false })
                        this.reloadSelector()
                        if (!o.success) this.setState({ error: o.error })
                    }}></div>
                    <IoIosTrash size={40} color="red" onClick={async () => {
                        let res = await fetch('/api/reserved/delete', {
                            method: 'post',
                            body: JSON.stringify({
                                time: t,
                            }),
                            headers: {
                                "Content-type": "application/json"
                            }
                        })
                        let o = await res.json()
                        this.setState({ modifying: false })
                        this.reloadSelector()
                        if (!o.success) this.setState({ error: o.error })
                    }} />
                </div>
                : <IoIosCreate size={40} color="rgba(20, 70, 234, 1)" onClick={() => this.setState({ modifying: true })} />}
        </div>
    }

    render = () => {
        const shadow = '0px 0px 20px 0px rgba(0, 0, 0, 0.2)'
        if (this.state.logged && !this.state.currents) this.getCurrents()
        if (this.state.logged && !this.state.days) this.reloadSelector()
        return !this.state.logged
            ? <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 400
            }}>
                <div id="errorContainer">
                    {this.state.error ? <div>
                        {this.state.error.split('\n').map(e => <p key={e}>{e}</p>)}
                    </div> : undefined}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: '0.5em',
                    boxShadow: shadow,
                    MozBoxShadow: shadow,
                    WebkitBoxShadow: shadow,
                }}>
                    <h1 style={{ margin: 0 }}>Inserisci password</h1>
                    <input
                        type="password"
                        style={{
                            padding: 2,
                            fontSize: 18,
                            height: 20,
                            marginTop: 10,
                            marginBottom: 15,
                        }}
                        placeholder="password"
                        onChange={v => this.setState({ password: v.currentTarget.value })}
                    />
                    <ReCAPTCHA
                        ref={this.capthcaCheckbox}
                        sitekey='6LdlP9cUAAAAAFmI2gSKmJAWbkfAb-kkq8lA1QDq'
                        onChange={token => this.setState({ captchaToken: token ?? undefined })}
                    />
                    <button
                        type="submit"
                        style={{
                            fontSize: 20,
                            padding: 5,
                            marginBottom: 0
                        }}
                        onClick={async () => {
                            let res = await fetch('/api/reserved/verification', {
                                method: 'post',
                                body: JSON.stringify({
                                    password: this.state.password,
                                    captchaToken: this.state.captchaToken
                                }),
                                headers: {
                                    "Content-type": "application/json"
                                }
                            })
                            let { success, error } = await res.json()
                            if (success) this.setState({ logged: true })
                            else {
                                this.setState({ error })
                                this.capthcaCheckbox.current?.reset()
                            }
                        }}
                    >conferma</button>
                </div>
            </div>
            : <div id="reserved">
                <div id="errorContainer">
                    {this.state.error ? <div>
                        {this.state.error.split('\n').map(e => <p key={e}>{e}</p>)}
                    </div> : undefined}
                </div>
                {this.state.currents}
                <div className="selector">
                    {this.renderSelector()}
                </div>
                {this.renderInfo()}
            </div>
    }
}


function formatDate(inputDate: string) {
    let date = new Date(inputDate);
    let timezoneOffset = moment().tz("Europe/Rome").utcOffset() / 60
    // date.setHours(date.getHours())
    let dd = date.getDate() + '';
    if (dd.length === 1)
        dd = '0' + dd;
    let MM = date.getMonth() + 1 + '';
    if (MM.length === 1)
        MM = '0' + MM;
    let yyyy = date.getFullYear();
    let hh = date.getHours() - timezoneOffset + '';
    if (hh.length === 1)
        hh = '0' + hh;
    let mm = date.getMinutes() + '';
    if (mm.length === 1)
        mm = '0' + mm;
    return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
}