import React, { Component } from 'react'

export default class NotFound extends Component {
    componentDidMount = () => {
        document.title = 'Dott. Morganti | Pagina inesistente'
    }

    render = () => <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20
    }}>
        <h1 style={{ fontSize: '3em' }}>404</h1>
        <h2>La pagina che stai provando a visualizzare non esiste</h2>
        <a className='button' href="/">torna alla home</a>
    </div>
}