import React, { Component } from 'react'

export default class Contatti extends Component {
    componentDidMount = () => {
        document.title = 'Dott. Morganti | Contatti'
    }

    render = () => <div>
        <div className="paragraph">
            <h1>Telefono</h1>
            <div className="contacttable">
                <h2>Studio</h2>
                <h2 className="flex-right"><a href="tel:+390342670686">+39 0342 670686</a></h2>
            </div>
            <p style={{
                backgroundColor: 'white',
                margin: 0,
                padding: 20,
                paddingTop: 0
            }}><a href="/orari?s=assistente">Orari assistente di studio</a></p>
            <div className="contacttable">
                <h2>Cellulare</h2>
                <h2 className="flex-right"><a href="tel:+393472703736">+39 347 270 3736</a></h2>
            </div>
        </div>
        <div className="paragraph">
            <span className="anchor" id="mappa"></span>
            <h1>Mappa</h1>
            <p>Viale Combattenti e Reduci 52, 23018, Talamona (SO)</p>
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe
                        title="map"
                        style={{ margin: 0, border: 0 }}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=viale%20combattenti%20e%20reduci%20talamona&t=&z=16&ie=UTF8&iwloc=&output=embed"
                        scrolling="no">
                    </iframe>
                </div>
            </div>
        </div>
        <a href="/appuntamenti" className="button" style={{ display: 'block', maxWidth: 350, margin: 'auto' }}><h2>Prendi un appuntamento</h2></a>
    </div>
}