import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import qs from 'query-string'

let hours: { [key: string]: { shifts: { start: string, end: string }[][], description?: JSX.Element } } = {
    morganti: {
        shifts: [
            [{ start: '15:15', end: '18:45' }],
            [{ start: '8:00', end: '10:15' }],
            [{ start: '9:45', end: '12:00' }],
            [{ start: '15:15', end: '18:45' }],
            [{ start: '15:15', end: '18:45' }],
        ],
        description: <div>
            <a href="/appuntamenti" className="button" style={{
                margin: 'auto',
                maxWidth: 200,
                display: 'block'
            }}> Prendi un appuntamento</a>
        </div>
    },
    borellini: {
        shifts: [
            [{ start: '8:00', end: '11:00' }],
            [{ start: '15:30', end: '19:00' }],
            [{ start: '10:00', end: '12:00' }, { start: '15:30', end: '18:30' }],
            [],
            [{ start: '15:00', end: '18:30' }],
            [{ start: '7:30', end: '10:30' }]
        ]
    },
    taddeo: {
        shifts: [
            [{ start: '15:30', end: '18:30' }],
            [{ start: '9:00', end: '11:30' }, { start: '17:00', end: '18:30' }],
            [],
            [{ start: '10:00', end: '12:00' }, { start: '15:30', end: '18:30' }],
            [{ start: '9:00', end: '12:00' }],
            [{ start: '7:30', end: '10:30' }]
        ]
    },
    invitti: {
        shifts: [
            [],
            [{ start: '11:00', end: '12:00' }]
        ]
    },
    assistente: {
        shifts: [
            [{ start: '8:30', end: '11:30' }, { start: '15:30', end: '18:00' }],
            [{ start: '8:30', end: '11:30' }],
            [{ start: '8:30', end: '11:30' }],
            [{ start: '8:30', end: '11:30' }],
            [{ start: '9:00', end: '11:00' }, { start: '15:00', end: '17:30' }]
        ],
        description: <div>
            <h2>L'assistente di studio è tenuta a</h2>
            <ol>
                <li>
                    Inoltrare al medico le richieste di ricette, visite specialistiche, esami strumentali e di
                    laboratorio previsti dal programma per le cronicità riscontrate
                </li>
                <li>
                    Dare tutte le informazioni circa il funzionamento dello studio associato
                </li>
                <li>
                    Riferire al medico le necessità emerse dai pazienti
                </li>
                <li>
                    Filtrare l'accesso allo studio e prendere appuntamenti per chi non avesse potuto dalla pagina web
                </li>
                <li>
                    Prendere gli appuntamenti per i prelievi di laboratorio effettuati dalle infermiere nei giorni
                    previsti, cioè il mercoledì e il giovedì
                </li>
            </ol>
        </div>
    },
    infermiere: {
        shifts: [
            [{ start: '8:00', end: '10:00' }, { start: '15:30', end: '17:30' }],
            [{ start: '8:00', end: '10:00' }],
            [{ start: '8:00', end: '10:00' }],
            [{ start: '8:00', end: '10:00' }]
        ],
        description: <div>
            <h2> Le infermiere posso eseguire(previa autorizzazione del medico curante):</h2>
            <ul>
                <li>Rilievi pressori</li>
                <li>Stick rapidi glicemia</li>
                <li>Stick rapidi urine</li>
                <li>Medicazioni di 1° e 2° livello</li>
                <li>Terapie parentali (solo in presenza del curante) intramuscolari e EV</li>
                <li>Desuture</li>
                <li>Tracciato ECG</li>
                <li>Spirometria Semplice</li>
            </ul>
            <p>Sono previsti, solo per gli aventi diritto, interventi domicialiari</p>
        </div>
    }
}

export default class Orari extends Component<RouteComponentProps, { selectedTime: string }> {
    select: any
    constructor(props: any) {
        super(props)
        this.state = { selectedTime: 'morganti' }
    }

    componentDidMount = () => {
        document.title = 'Dott. Morganti | Orari'

        let s = qs.parse(this.props.location.search)['s']
        if (typeof s == "string" && hours[s]) {
            this.setState({ selectedTime: s })
            this.select.selectedIndex = Object.keys(hours).indexOf(s)
        }
    }

    renderCalendar = () => {
        const week = ['lun', 'mar', 'mer', 'gio', 'ven', 'sab']
        let calendar: JSX.Element[] = []
        for (const day of week) calendar.push(<h2 key={day + 'h'} className="weekday">{day}</h2>)
        for (const day of week) {
            let separatorLines: JSX.Element[] = []
            for (let i = 0; i < 17; i++) separatorLines.push(<div
                key={day + i}
                className="separatorLine"
                style={{ top: i / 18 * 500 }}
            >
                {(day === 'lun' && i % 2 === 0) ? <span>{i + 6 + ':00'}</span> : undefined}
            </div>)

            let shiftElements: JSX.Element[] = []
            let shifts = hours[this.state.selectedTime].shifts[week.indexOf(day)]
            if (Array.isArray(shifts)) for (const shift of shifts) {
                let s = shift.start.split(':').map(v => parseInt(v))
                let sp = (s[0] - 6) / 18 + s[1] / (18 * 60)
                let e = shift.end.split(':').map(v => parseInt(v))
                let ep = (e[0] - 6) / 18 + e[1] / (18 * 60)
                let top = sp * 500
                let height = (ep - sp) * 500

                shiftElements.push(<div key={day + shift.start} className="shift" style={{ top, height }}>
                    <h3>{shift.start + ' - ' + shift.end}</h3>
                </div>)
            }

            calendar.push(<div key={day} className="dayContainer">{[...separatorLines, ...shiftElements]}</div>)
        }
        let description = hours[this.state.selectedTime].description
        if (description) calendar.push(<div key='desc' className='calendarDescription'>{description}</div>)
        return calendar
    }

    render = () => {
        return <div>
            <select ref={r => { if (r) this.select = r }} id="hoursSelect" onChange={e => this.setState({ selectedTime: e.target.value })}>
                <option value="morganti">dr. Mauro Morganti</option>
                <option value="borellini">dr. Paolo Borellini</option>
                <option value="taddeo">dr.ssa Maria Pia Taddeo</option>
                <option value="invitti">dr. Gianfranco Invitti</option>
                <option value="assistente">Assistente di Studio</option>
                <option value="infermiere">Personale infermieristico</option>
            </select>
            <div className="calendar">
                {this.renderCalendar()}
            </div>
        </div>
    }
}