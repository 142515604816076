import React, { Component } from 'react'
import { IoIosCalendar, IoIosTime, IoIosContact } from 'react-icons/io'
import studio from '../assets/studio.png'

export default class Index extends Component {
    componentDidMount = () => {
        document.title = 'Dott. Morganti'
    }

    render() {
        return <div>
            <div className="paragraph" style={{ marginBottom: 50 }}>
                <h1>
                    Dottor Mauro Morganti
                </h1>
                <p>
                    <span>Specialista in Anatomia ed Istologia Patologiche</span><br />
                    <span>Abilitato Medicina d'Urgenza</span><br />
                    <span>MMG</span>
                </p>
            </div>

            <div className="paragraph" style={{
                marginTop: 0,
                borderRadius: '1.2em',
                backgroundImage: `url(${studio})`,
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                boxShadow: '0px 3px 15px 0px rgba(0, 0, 0, 0.1)'
            }}>
                <div style={{
                    padding: '50px 20px',
                    borderRadius: '1.2em',
                    background: 'linear-gradient(90deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.7) 70%, rgba(255,255,255,0.4) 100%)'
                }}>
                    <h1>
                        Medicina di gruppo di Talamona
                    </h1>
                    <p>
                        Dr. Borellini Paolo <br />
                        Dr.ssa Taddeo Maria Pia <br />
                        Dr. Invitti Gianfranco <br />
                        Dr. Morganti Mauro <br />
                    </p>
                    <a href="/contatti#mappa" className="button">Come raggiungerci</a>
                </div>
            </div>

            <div className="cardContainer paragraph">
                <div className="card">
                    <IoIosCalendar />
                    <h2>Appuntamenti</h2>
                    <p>Appuntamenti dal lunedì al venerdì</p>
                    <a href="/appuntamenti" className="button">Prendi un appuntamento</a>
                </div>
                <div className="card">
                    <IoIosTime />
                    <h2>Orari</h2>
                    <p>Controlla gli orari del personale</p>
                    <a href="/orari" className="button">Orari</a>
                </div>
                <div className="card">
                    <IoIosContact />
                    <h2>Contatti</h2>
                    <p>
                        Studio: <a href="tel:+390342670686">+39 0342 670686</a><br />
                        Cellulare: <a href="tel:+393472703736">+39 347 270 3736</a>
                    </p>
                    <a href="/contatti" className="button">Altri contatti</a>
                </div>
            </div>
        </div >
    }
}