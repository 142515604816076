import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
    componentDidMount = () => {
        document.title = 'Dott. Morganti | Politica sulla Pirvacy'
    }
    render() {
        return <div className="paragraph">
            <h1>Politica sulla Privacy</h1>
            <h3>Informativa ai sensi degli artt. 13 e 14 del Regolamento UE 2016/679 </h3>
            <p>
                Gentili Signori, <br />
                ai sensi degli artt. 13 e 14 del “Regolamento UE n. 2016/679 relativo alla protezione
                delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera
                circolazione di tali dati”, desidero informarVi di quanto segue:
            </p>
            <h2>TITOLARE DEL TRATTAMENTO</h2>
            <p>
                Titolare del trattamento è il dott. Mauro Morganti <br />
                tel. +39 347 270 3736; Viale Combattenti e Reduci 52, Talamona (SO)
            </p>
            <h2>FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO</h2>
            <p>
                I Vostri dati personali, ivi compresi i dati relativi alla Vostra salute, saranno utilizzati
                per svolgere attività necessarie per prevenzione, diagnosi, cura, riabilitazione o per altre
                prestazioni da Voi richieste, farmaceutiche e specialistiche. <br />
                Si tratta dei dati forniti da Voi stessi o che sono acquisiti altrove, ma con il Vostro
                consenso, ad esempio in caso di ricovero o di risultati di esami clinici. <br />
                I Vostri dati personali saranno altresì trattati per assolvere eventuali obblighi previsti
                dalla legge, da un regolamento, o da una normativa comunitaria, così come per la gestione
                delle attività amministrativo-contabili, connesse all’esecuzione del rapporto tra le parti. <br />
                In ogni caso Vi rammento che tali dati sono trattati da o sotto la responsabilità di un
                professionista soggetto al segreto professionale. <br />
                In relazione a quanto sopra, il trattamento dei Vostri dati personali avviene mediante
                strumenti manuali, informatici e telematici comunque idonei a garantire la sicurezza e la
                riservatezza dei dati stessi. <br />
            </p>
            <h2>AMBITO DI CIRCOLAZIONE E COMUNICAZIONE DEI DATI</h2>
            <p>
                Potranno avere accesso alle informazioni che Vi riguardano le persone autorizzate dal Titolare
                nei limiti strettamente necessari ai loro compiti (i medici in associazione, i medici sostituti,
                gli specialisti, l’infermiera, la segretaria, il commercialista, il consulente informatico);
                nei casi previsti dalla legge potranno altresì essere comunicati ai seguenti enti: Agenzia
                delle Entrate, Aziende sanitarie, Servizio Sanitario Nazionale, INPS, INAIL. <br />
                Si potranno fornire informazioni sul Vostro stato di salute a familiari e conoscenti solo su
                Vostra espressa e specifica indicazione.
            </p>
            <h2>CONSERVAZIONE DEI DATI</h2>
            <p>
                I Vostri dati personali saranno custoditi e conservati per il tempo strettamente necessario
                alla gestione delle finalità per le quali i dati stessi sono stati raccolti e in osservanza
                dei limiti previsti per la conservazione dei dati clinici e cartelle cliniche. <br />
                I Vostri dati personali utilizzati per gli adempimenti tributari saranno conservati nei
                limiti di tempo previsti dalle norme fiscali sull’accertamento e dalle norme del Codice
                civile.
            </p>
            <h2>DIRITTI DELL’INTERESSATO</h2>
            <p>
                In qualunque momento potrete conoscere i dati che Vi riguardano, sapere come sono stati
                acquisiti, verificare se sono esatti, completi, aggiornati e ben custoditi e far valere i
                Vostri diritti al riguardo, chiedere la cancellazione o la limitazione nell’utilizzo dei
                medesimi, opporVi al trattamento nonché il diritto alla portabilità e il reclamo al Garante.
                Avete altresì il diritto di revocare il Vostro consenso in qualsiasi momento senza
                pregiudicare la liceità del trattamento prima della revoca. <br />
                Per l’esercizio dei Vostri diritti potrete rivolgerVi al sottoscritto in qualità di titolare
                del trattamento utilizzando gli estremi di contatto suindicati. <br />
                Per ogni ulteriore attività da svolgere nel Vostro interesse, o per finalità scientifiche o
                di ricerca, sarà mia cura informarVi in modo più preciso.
            </p>
        </div>
    }
}