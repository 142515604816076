import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { IconContext } from 'react-icons'
import { IoIosPin, IoIosCall, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import Index from './routes'
import Regolamento from './routes/regolamento'
import Contatti from './routes/contatti'
import Orari from './routes/orari'
import Appuntamenti from './routes/appuntamenti'
// import Ricette from './routes/ricette'
import NotFound from './routes/notfound'
import Reserved from './routes/reserved'
import PrivacyPolicy from './routes/privacy'

import './App.scss';

const history = createBrowserHistory()

export default class App extends Component<{}, { menuIsOpen: boolean }> {
    state = { menuIsOpen: false }

    updateMenu = () => {
        document.querySelectorAll('nav.flex-right>a').forEach(menuElement => {
            let href = menuElement.getAttribute('href')
            if (!href) return
            if (window.location.href.includes(href)) menuElement.classList.add('selected')
            else menuElement.classList.remove('selected')
        })
    }

    componentDidMount = () => {
        this.updateMenu()

        // const preheader = document.getElementById('preheader')
        const content = document.getElementById('content')
        // let animating = false

        document.querySelectorAll('a').forEach(a => {
            a.addEventListener('click', e => {
                e.preventDefault()

                content?.classList.remove('faded')
                setImmediate(() => {
                    // if (!animating) {
                    //     animating = true
                    //     preheader?.classList.add('shifted')
                    //     setTimeout(() => {
                    //         preheader?.classList.remove('shifted')
                    //         animating = false
                    //     }, 2000)
                    // }
                    content?.classList.add('faded')
                    setTimeout(() => {
                        this.setState({ menuIsOpen: false })
                        history.push(a.href.replace(window.location.origin, ''))
                        this.updateMenu()
                    }, 300)
                })
            })
        })
    }

    render() {
        return <IconContext.Provider value={{ className: "ionicon" }}>
            <header>
                <div id="preheader">
                    <div>
                        <IoIosPin />
                        <a href="/contatti#mappa">Viale Combattenti e Reduci 52, Talamona</a>
                    </div>
                    <div className="flex-right">
                        <IoIosCall />
                        <a href="tel:+390342670686">+39 0342 670686</a>
                    </div>
                </div>
                <div id='header'>
                    <a href="/">
                        <h1 id="dr">dr. Mauro Morganti</h1>
                    </a>
                    <nav className="flex-right" style={{ maxHeight: this.state.menuIsOpen ? 280 : undefined }}>
                        <a href="/regolamento">Regolamento</a>
                        <span className="separationDot">·</span>
                        <a href="/contatti">Contatti</a>
                        <span className="separationDot">·</span>
                        <a href="/orari">Orari</a>
                        <span className="separationDot">·</span>
                        <a href="/appuntamenti">Appuntamenti</a>
                    </nav>
                    <button id="menuIcon" onClick={(e) => {
                        e.preventDefault()
                        this.setState({ menuIsOpen: !this.state.menuIsOpen })
                    }}>
                        {this.state.menuIsOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                    <script src="/assets/js/menu.js"></script>
                </div>
            </header>
            <div style={{
                padding: '0px 40px',
                flex: '1 0 auto'
            }}>
                <div id="content">
                    <Router history={history}>
                        <Switch>
                            <Route exact path='/regolamento' component={Regolamento} />
                            <Route exact path='/contatti' component={Contatti} />
                            <Route exact path='/orari' component={Orari} />
                            <Route exact path='/appuntamenti' component={Appuntamenti} />
                            {/* <Route exact path='/ricette' component={Ricette} /> */}
                            <Route exact path='/reserved' component={Reserved} />
                            <Route exact path='/privacy' component={PrivacyPolicy} />
                            <Route exact path='/' component={Index} />
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </div>
            </div>
            <footer>
                <div id="footer">
                    <div>
                        <a href="/">
                            <h1>dr. Mauro Morganti</h1>
                        </a>
                        <p>
                            Sito web sviluppato da Tommaso Morganti<br />
                            © 2020. Tutti i diritti riservati.
                    </p>
                    </div>
                    <div>
                        <h2>Link utili</h2>
                        <ul>
                            <li><a href="/regolamento">Regolamento</a></li>
                            <li><a href="/orari">Orari</a></li>
                            <li><a href="/appuntamenti">Appuntamenti</a></li>
                            <li><a href="/privacy">Politica sulla Privacy</a></li>
                            <li><a href="/reserved">Area Riservata</a></li>
                        </ul>
                    </div>
                    <div>
                        <a href="/contatti">
                            <h2>Contatti</h2>
                        </a>
                        <p>
                            <a href="/orari?s=assistente">Orari assistente di studio</a><br />
                            <span>Studio: <a href="tel:+390342670686">+39 0342 670686</a></span>
                        </p>
                        <p>
                            <span>Cellulare: <a href="tel:+393472703736">+39 347 270 3736</a></span>
                        </p>
                    </div>
                </div>
            </footer>
        </IconContext.Provider >
    }
}
