import React, { Component } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { SyncLoader } from 'react-spinners'
import ReCAPTCHA from 'react-google-recaptcha'

interface AppointmentsState {
    days?: {
        header: string
        date: string
        appointments: {
            time: string
            available: boolean
        }[]
    }[]
    currentDay: number
    selectedAppointment?: string
    firstNameInput?: string
    lastNameInput?: string
    emailInput?: string
    phoneInput: string
    loading: boolean
    error?: string
    succeeded: boolean,
    captchaToken?: string,
}

export default class Appuntamenti extends Component<{}, AppointmentsState> {
    constructor(props: any) {
        super(props)
        this.state = {
            currentDay: 0,
            phoneInput: '',
            loading: false,
            succeeded: false
        }

        this.reloadCalendar()
    }

    componentDidMount = () => {
        document.title = 'Dott. Morganti | Appuntamenti'
    }

    reloadCalendar = async () => {
        let res = await fetch('/api/appointments')
        let obj = await res.json()
        if (obj.success) this.setState({ days: obj.data.days, currentDay: 0, selectedAppointment: undefined })
        else { this.setState({ error: obj.error }) }
    }

    renderCalendar: () => JSX.Element[] | undefined = () => {
        if (!this.state.days) return
        let lines: JSX.Element[] = []
        let divisions = 4
        for (let i = 0; i < (this.state.days[this.state.currentDay].appointments.length - parseInt(this.state.days[this.state.currentDay].appointments[0].time.split(':')[1]) / (60 / divisions)) / divisions; i++) {
            lines.push(<div key={"line" + i} className="separatorLine" style={{
                top: (i * divisions + divisions - parseInt(this.state.days[this.state.currentDay].appointments[0].time.split(':')[1]) / (60 / divisions)) * 30
            }} />)
        }

        let appointments: JSX.Element[] = []
        for (const appointment of this.state.days[this.state.currentDay].appointments) {
            let appointmentIdentifier = this.state.currentDay + 'A' + appointment.time
            appointments.push(<div
                key={appointmentIdentifier}
                className={[
                    appointment.available ? undefined : 'unselectable',
                    this.state.selectedAppointment === appointmentIdentifier ? 'selected' : undefined
                ].join(' ')}
                onClick={() => {
                    if (appointment.available) this.setState({ selectedAppointment: appointmentIdentifier })
                }}
            >
                <p>{appointment.time}</p>
            </div>)
        }

        return [
            <div key='div' className="legend">
                <div style={{ marginBottom: 8, fontWeight: 'bold' }}><span>Seleziona l'orario dell'appuntamento</span></div>
                <div><div className="colorSquare" style={{ backgroundColor: '#999' }} /> <span>Non disponibile</span></div>
                <div><div className="colorSquare" style={{ backgroundColor: 'rgba(20, 70, 234, 1)' }} /> <span>Disponibile</span></div>
                <div><div className="colorSquare" style={{ backgroundColor: '#07baec' }} /> <span>Selezionato</span></div>
            </div>,
            <div key="weekdaySelector" className="weekdaySelector">
                <div
                    className={"arrowContainer" + (this.state.currentDay < 1 ? ' unselectable' : '')}
                    onClick={() => {
                        if (this.state.currentDay > 0) this.setState({ currentDay: this.state.currentDay - 1 })
                    }}
                >
                    <IoIosArrowBack />
                </div>
                <h2 className="weekday">
                    {this.state.days[this.state.currentDay].header}
                </h2>
                <div
                    className={"arrowContainer flex-right" + (this.state.currentDay > 8 ? ' unselectable' : '')}
                    onClick={() => {
                        if (this.state.currentDay < 9) this.setState({ currentDay: this.state.currentDay + 1 })
                    }}
                >
                    <IoIosArrowForward />
                </div>
            </div>,
            <div key="dayContainer" className="dayContainer">
                {lines}
                <div className="appointmentsContainer">
                    {appointments}
                </div>
            </div>
        ]
    }

    render = () => {
        let d = this.state.days && this.state.selectedAppointment ? new Date(this.state.days[parseInt(this.state.selectedAppointment.split('A')[0])].date) : undefined
        let date = d && this.state.selectedAppointment ? `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} – ${this.state.selectedAppointment.split('A')[1]}` : undefined
        return this.state.succeeded ? <div className="paragraph successScreen">
            <h1>Grazie {this.state.firstNameInput}!</h1>
            <h2>Il tuo appuntamento per <span>{date}</span> è stato registrato</h2>
            <p>Controlla la tua casella di posta (<span>{this.state.emailInput}</span>) per un email di conferma</p>
            <p>Se non ti è arrivata alcun'email controlla nella cartella spam, ma non preoccuparti, il tuo appuntamento è stato registrato</p>
            <p>Presentati all'orario stabilito nello studio, aspetta in sala di attesa finché il personale non verrà a chiamarti</p>
        </div> : <div className="paragraph" id="appointmentsGrid">
                <div id="errorContainer">
                    {this.state.error ? <div>
                        {this.state.error.split('\n').map(e => <p key={e}>{e}</p>)}
                    </div> : undefined}
                </div>
                <div id="appointmentTime">{this.renderCalendar()}</div>
                <form id="appointmentForm" onSubmit={evt => {
                    evt.preventDefault()
                    if (!this.state.days) return
                    if (!this.state.selectedAppointment) {
                        this.setState({ error: 'Seleziona l\'orario dell\'appuntamento' })
                        return
                    }
                    if (!this.state.captchaToken) {
                        this.setState({ error: 'Clicca sulla casella "Non sono un robot"' })
                        return
                    }
                    window.scrollTo({ top: 0 })
                    this.setState({ loading: true })
                    fetch('/api/appointments', {
                        method: 'post',
                        body: JSON.stringify({
                            firstName: this.state.firstNameInput,
                            lastName: this.state.lastNameInput,
                            phone: this.state.phoneInput,
                            email: this.state.emailInput,
                            time: this.state.days[parseInt(this.state.selectedAppointment.split('A')[0])].date + 'T' + this.state.selectedAppointment.split('A')[1],
                            captchaToken: this.state.captchaToken
                        }),
                        headers: {
                            "Content-type": "application/json"
                        }
                    }).then(async res => {
                        let obj = await res.json()
                        this.setState({ loading: false, error: obj.success ? undefined : obj.error, succeeded: obj.success })
                        if (obj.shouldReloadCalendar) this.reloadCalendar()
                    }).catch(e => {
                        this.setState({ loading: false, error: 'C\'è stato un errore di connessione, riprova più tardi' })
                    })
                }}>
                    <h1>Inserisci le tue informazioni</h1>
                    <div className="nameContainer">
                        <input name="firstName" type="text" placeholder="Nome" maxLength={20} onChange={(e) => this.setState({ firstNameInput: e.target.value })} required />
                        <input name="lastName" type="text" placeholder="Cognome" maxLength={20} onChange={(e) => this.setState({ lastNameInput: e.target.value })} required />
                    </div>
                    <h2>
                        Contatti: <br />
                        <span style={{ display: 'inline-block', fontSize: 14, textAlign: 'left' }}>
                            Useremo questo contatti per mandarti una conferma della registrazione del tuo appuntamento
                            ed eventualmente per contattarti privatamente riguardo al tuo trattamento.
                        </span>
                    </h2>
                    <div className="nameContainer">
                        <span className="prefix">+39</span>
                        <input name="phoneNumber" type="tel" pattern="[0-9]+" value={this.state.phoneInput} onChange={evt => {
                            let v = evt.target.value
                            this.setState({ phoneInput: v.replace(/[^0-9]/g, '') })
                        }} placeholder="Numero di telefono" maxLength={15} required />
                    </div>
                    <div className="nameContainer">
                        <input name="email" type="email" placeholder="Indirizzo email" onChange={(e) => this.setState({ emailInput: e.target.value })} required />
                    </div>
                    {date ? <p>
                        Orario selezionato: {date}
                    </p> : undefined}
                    <p>
                        Prima di confermare <strong>accertati di aver <a href="/regolamento">letto il regolamento</a></strong> per
                        sapere come ottenere il servizio più addato a te
                    </p>
                    <label className="checkboxContainer">
                        <input type="checkbox" name="privacy" required />
                        <span>Confermo di aver letto e accettato la <a href="/privacy">politica sulla privacy</a></span>
                    </label>
                    <div style={{
                        alignSelf: 'center'
                    }}>
                        <ReCAPTCHA
                            sitekey='6LdlP9cUAAAAAFmI2gSKmJAWbkfAb-kkq8lA1QDq'
                            onChange={token => this.setState({ captchaToken: token ?? undefined })}
                        />
                    </div>
                    <button style={{ alignSelf: 'center' }} type="submit">Prendi appuntamento</button>
                </form>
                {this.state.loading ? <div id="loadingShader">
                    <SyncLoader />
                </div> : undefined}
            </div>
    }
}